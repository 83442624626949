import { Container } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useAnalytics } from 'modules/analytics/hooks/useAnalytics';
import { AnonymousLogin, LoginForm, OrDivider } from 'modules/authentication';
import { AuthService } from 'modules/firebase';
import { AuthLogo } from 'modules/navigation';
import { ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';
import { auth, spacers } from 'styles';

const authService = AuthService();

const CartAuthentication: React.FC = () => {
  const { sendCartItemsInteraction, sendContactInteraction } = useAnalytics();
  const { products } = useSelector((state: ApplicationState) => state.cart);

  const sendCartInteractions = async () => {
    const data = await authService.getUserData();
    if (data)
      sendContactInteraction({
        uid: data?.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        isSubscribed: data.subscribtion,
      }).then(() => {
        sendCartItemsInteraction(
          {
            interactionProducts: products.map((product) => {
              return {
                Name: product.title,
                ItemId: product.id,
              };
            }),
          },
          data.id,
        );
      });
  };
  return (
    <Container className={auth.loginFlex}>
      <AuthLogo />
      <div css={auth.cartWrapper}>
        <section>
          <div css={auth.anonymousWrapper}>
            <div css={auth.formTitle}>
              <h2 css={[spacers.bottom.medium, auth.title]}>
                <FormattedMessage id="cart.login.guest.title" />
              </h2>
            </div>
            <p css={auth.text.large}>
              <FormattedMessage id="cart.login.guest.subtitle" />
            </p>

            <AnonymousLogin />
          </div>
        </section>

        <div css={auth.cartDivider}>
          <span></span>
          <p css={[auth.text.large, auth.dividerPadding]}>
            <FormattedMessage id="shared.or" />
          </p>
          <span></span>
        </div>

        <section css={auth.dividerWrapper}>
          <OrDivider />
        </section>

        <section>
          <LoginForm
            navigatePath="/kosarica/podaci/"
            sendCartInteractions={sendCartInteractions}
          />
        </section>
      </div>

      <p css={auth.text.base}>
        &copy; {new Date().getFullYear()}{' '}
        <FormattedMessage id="shared.copyright" />
      </p>
    </Container>
  );
};

export default CartAuthentication;
